import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDMBy999T2kzCAdK_R_MBXig49wu_VuNVc",
  authDomain: "syncmeet-b18d0.firebaseapp.com",
  projectId: "syncmeet-b18d0",
  storageBucket: "syncmeet-b18d0.appspot.com",
  messagingSenderId: "12239479570",
  appId: "1:12239479570:web:12d36a96266168fe4a49cb",
  measurementId: "G-KK903ZWB21"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

if (window.location.hostname === 'localhost') {
  //connectFirestoreEmulator(db, 'localhost', 8080);
  console.log('Connected to Firestore emulator.');
} else {
  console.log('Connected to live Firestore service.');
}

export { db };
