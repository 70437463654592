import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, onSnapshot, updateDoc, arrayUnion, arrayRemove, collection, getDocs, setDoc } from 'firebase/firestore';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './Lobby.css';

interface Coordinate {
  latitude: number;
  longitude: number;
}

interface Address {
  address: string;
  coordinate: Coordinate;
}

interface Vote {
  userId: string;
  date: number;
}

interface Answer {
  id: string;
  value: string;
  votes: Vote[];
}

interface Question {
  id: string;
  title: string;
  type: 'boolean' | 'string' | 'date';
  answers: Answer[];
}

interface Event {
  id: string;
  status: 'opened' | 'closed';
  title: string;
  ownerId: string;
  address?: Address;
  date: number;
  description: string;
  questions: Question[];
}

interface User {
  id: string;
  name: string;
  email: string;
  anonymous: boolean;
  roomIds: string[];
}

interface RoomModel {
  id: string;
  name: string;
  status: 'public' | 'private';
  sharableLink: string;
  owner: string;
  events: Event[];
  usersIds: string[];
}

const Lobby: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const navigate = useNavigate();
  const [roomDetails, setRoomDetails] = useState<RoomModel | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [availableRooms, setAvailableRooms] = useState<{ id: string; name: string }[]>([]);
  const [isJoining, setIsJoining] = useState(false);
  const [localUser, setLocalUser] = useState<User | null>(null);

  useEffect(() => {
    if (roomId) {
      const storedUser = localStorage.getItem(`anonymousUser_${roomId}`);
      if (storedUser) {
        setLocalUser(JSON.parse(storedUser));
      } else {
        setLocalUser(null);
      }

      const roomRef = doc(db, 'rooms', roomId);
      const unsubscribe = onSnapshot(roomRef, async (doc) => {
        if (doc.exists()) {
          const data = doc.data() as RoomModel;
          setRoomDetails(data);
          
          const usersRef = collection(db, 'users');
          const userSnapshots = await getDocs(usersRef);
          const fetchedUsers = userSnapshots.docs
            .map(doc => doc.data() as User)
            .filter(user => data.usersIds.includes(user.id));
          setUsers(fetchedUsers);
        } else {
          console.log("No such room!");
          navigate('/');
        }
      });
      return () => unsubscribe();
    } else {
      const fetchRooms = async () => {
        const roomsCollection = collection(db, 'rooms');
        const roomSnapshot = await getDocs(roomsCollection);
        setAvailableRooms(roomSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name
        })));
      };
      fetchRooms();
    }
  }, [roomId, navigate]);

  const handleJoinClick = async () => {
    if (!roomId || !roomDetails) return;
    const userName = prompt("Please enter your name:");
    if (userName) {
      setIsJoining(true);
      try {
        const newUser: User = {
          id: Date.now().toString(),
          name: userName,
          email: '',
          anonymous: true,
          roomIds: [roomId]
        };
        
        await setDoc(doc(db, 'users', newUser.id), newUser);
        
        const roomRef = doc(db, 'rooms', roomId);
        await updateDoc(roomRef, { usersIds: arrayUnion(newUser.id) });
        
        localStorage.setItem(`anonymousUser_${roomId}`, JSON.stringify(newUser));
        setLocalUser(newUser);
        
        const usersRef = collection(db, 'users');
        const userSnapshots = await getDocs(usersRef);
        const fetchedUsers = userSnapshots.docs
          .map(doc => doc.data() as User)
          .filter(user => roomDetails.usersIds.includes(user.id));
        setUsers(fetchedUsers);
      } catch (error) {
        console.error("Error joining the room:", error);
        alert("There was an error joining the room. Please try again.");
      } finally {
        setIsJoining(false);
      }
    }
  };

  const handleLeaveRoom = async () => {
    if (!roomId || !roomDetails || !localUser) return;
    try {
      const roomRef = doc(db, 'rooms', roomId);
      await updateDoc(roomRef, { usersIds: arrayRemove(localUser.id) });

      const userRef = doc(db, 'users', localUser.id);
      await updateDoc(userRef, { roomIds: arrayRemove(roomId) });

      localStorage.removeItem(`anonymousUser_${roomId}`);
      setLocalUser(null);
      navigate('/'); // Navigate back to the room list or home page
    } catch (error) {
      console.error("Error leaving the room:", error);
      alert("There was an error leaving the room. Please try again.");
    }
  };

  const handleVote = async (questionId: string, answerId: string) => {
    if (!roomDetails || !roomId || !localUser) return;
    const newVote: Vote = { userId: localUser.id, date: Date.now() };
    
    const updatedEvents = roomDetails.events.map(event => ({
      ...event,
      questions: event.questions.map(q => 
        q.id === questionId 
          ? {
              ...q, 
              answers: q.answers.map(a => 
                a.id === answerId
                  ? {
                      ...a,
                      votes: [...a.votes, newVote]
                    }
                  : a
              )
            }
          : q
      )
    }));

    const roomRef = doc(db, 'rooms', roomId);
    await updateDoc(roomRef, { events: updatedEvents });
  };

  if (!roomId) {
    return (
      <div className="room-list-container">
        <h2>Available Rooms</h2>
        <ul>
          {availableRooms.map(room => (
            <li key={room.id}>
              <button onClick={() => navigate(`/room/${room.id}`)}>{room.name}</button>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (!roomDetails || roomDetails.events.length === 0) {
    return <div>Loading...</div>;
  }

  const event = roomDetails.events[0];

  return (
    <div className="lobby-container">
      <div className="lobby-content">
        {localUser && (
          <div className="user-status-card content-container">
            <h3 className="content-title">Logged In User</h3>
            <p>You are logged in as <strong>{localUser.name}</strong> (Anonymous)</p>
          </div>
        )}
        <div className="lobby-columns">
          <div className="lobby-column">
            <div className="room-details content-container">
              <h2 className="content-title">{event.title}</h2>
              <p className="room-description">{event.description}</p>
              {event.address && (
                <p className="room-info">
                  <span className="info-label">Location: </span>
                  {event.address.coordinate ? (
                    <a 
                      href={`https://www.google.com/maps/search/?api=1&query=${event.address.coordinate.latitude},${event.address.coordinate.longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="location-link"
                    >
                      {event.address.address}
                    </a>
                  ) : (
                    <span>{event.address.address}</span>
                  )}
                </p>
              )}
              <p className="room-info">
                <span className="info-label">Date: </span>
                <span className="info-value">{new Date(event.date).toLocaleDateString()}</span>
              </p>
              <p className="room-info">
                <span className="info-label">Time: </span>
                <span className="info-value">{new Date(event.date).toLocaleTimeString()}</span>
              </p>
              {localUser ? (
                <button 
                  onClick={handleLeaveRoom} 
                  className="leave-button"
                >
                  Leave Room
                </button>
              ) : (
                <button 
                  onClick={handleJoinClick} 
                  className="join-button"
                  disabled={isJoining}
                >
                  {isJoining ? 'Joining...' : 'Wanna Join?'}
                </button>
              )}
              {isJoining && <div className="loading-indicator">Adding you to the room...</div>}
              {event.address && event.address.coordinate && (
                <div className="map-container">
                  <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
                    <GoogleMap
                      mapContainerStyle={{ width: '100%', height: '300px' }}
                      center={{lat: event.address.coordinate.latitude, lng: event.address.coordinate.longitude}}
                      zoom={15}
                    >
                      <Marker position={{lat: event.address.coordinate.latitude, lng: event.address.coordinate.longitude}} />
                    </GoogleMap>
                  </LoadScript>
                </div>
              )}
            </div>
          </div>
          
          <div className="lobby-column">
            <div className="participants-container content-container">
              <h2 className="content-title">Participants</h2>
              <div className="lobby-table">
                {users.map((user) => (
                  <div key={user.id} className="lobby-item">
                    <span className="user-name">{user.name} {user.anonymous ? '(Anonymous)' : ''}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="lobby-column">
            <div className="questions-container content-container">
              <h2 className="content-title">Questions</h2>
              {event.questions.map((question) => (
                <div key={question.id} className="question-container">
                  <h3>{question.title}</h3>
                  {question.answers.map((answer) => (
                    <button 
                      key={answer.id} 
                      onClick={() => handleVote(question.id, answer.id)}
                      className="answer-button"
                      disabled={!localUser}
                    >
                      {answer.value} ({answer.votes.length} votes)
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lobby;